<template>
  <div
    class="v-progress-bar-radial"
    :style="`--stroke: ${circleSize}; --strokeDashArray: ${strokeDashArray}; width: ${size * 2}px; height: ${size * 2}px;`"
  >
    <div class="v-progress-bar-radial-slot">
      <slot />
    </div>
    <svg
      class="v-progress-bar-radial-svg"
      :height="size * 2"
      :viewBox="`0 0 ${size * 2} ${size * 2}`"
      :width="size * 2"
    >
      <circle
        :class="
          milestones.length > 1
            ? 'v-progress-bar-radial-circle'
            : 'v-progress-bar-radial-circle v-progress-bar-radial-circle__filled'
        "
        :cx="size"
        :cy="size"
        :r="radius"
        :stroke-width="circleSize"
        fill="none"
        stroke="#ddd"
      />
      <path
        v-if="milestones.length > 1"
        class="v-progress-bar-radial-runner"
        :d="direction"
        :stroke-width="circleSize"
        fill="none"
        stroke="#000"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const {
  circleSize = 5,
  clockwise = true,
  milestones,
  size = 25,
  value
} = defineProps<{
  value: number
  milestones: number[]
  clockwise?: boolean
  size?: number
  circleSize?: number
}>()

const startAngle = -90 //we start at -90 because it is where top point of bar
const strokeDashArrayWidth = 4

const angle = ref<number>(0)
const strokeDashArray = ref<string>('')
onMounted(() => {
  const runnerLength = 2 * Math.PI * radius.value

  const maxValue = milestones[milestones.length - 1]

  const milestonesToLength = milestones.map((milestone) => (milestone / maxValue) * runnerLength)

  strokeDashArray.value = milestonesToLength
    .map((milestone, index) =>
      index > 0
        ? `${milestone - milestonesToLength[index - 1] - strokeDashArrayWidth} ${strokeDashArrayWidth}`
        : `${milestone - strokeDashArrayWidth} ${strokeDashArrayWidth}`
    )
    .join(', ')

  angle.value = (value / maxValue) * 360
})

watch(
  () => value,
  (newValue) => {
    const maxValue = milestones[milestones.length - 1]
    gsap.to(angle, {
      duration: 0.65,
      value: newValue > maxValue ? 360 : (newValue / maxValue) * 360
    })
  }
)

function polarToCartesianX(angleLocal: number): number {
  return radius.value * Math.cos((angleLocal * Math.PI) / 180)
}

function polarToCartesianY(angleLocal: number): number {
  return radius.value * Math.sin((angleLocal * Math.PI) / 180)
}

const radius = computed<number>(() => size - circleSize / 2)

const direction = computed<string>(() => {
  const angleLoc = Math.min(angle.value, 359.5)

  const endAngle = startAngle + angleLoc * (+clockwise * 2 - 1)

  const x1 = size + polarToCartesianX(startAngle)
  const x2 = size + polarToCartesianX(endAngle)
  const y1 = size + polarToCartesianY(startAngle)
  const y2 = size + polarToCartesianY(endAngle)

  return ['M', x1, y1, 'A', radius.value, radius.value, 0, +(angleLoc > 180), +clockwise, x2, y2].join(
    ' '
  )
})
</script>

<style lang="scss">
@use 'assets/variables';

$stroke: var(--stroke, 6px);
$strokeDashArray: var(--strokeDashArray, '');

.v-progress-bar-radial {
  display: grid;
  grid-template-areas: 'pic';
}

.v-progress-bar-radial-svg {
  grid-area: pic;
  z-index: 1;
}

.v-progress-bar-radial-slot {
  grid-area: pic;
  z-index: 2;

  width: 100%;
  height: 100%;
}

.v-progress-bar-radial-runner {
  stroke-width: $stroke;
  stroke: variables.$PrimaryBackgroundColor;
  stroke-dasharray: $strokeDashArray;
}

.v-progress-bar-radial-circle {
  stroke-width: $stroke;
  stroke: variables.$PrimaryBackgroundColorOpaq25;

  &__filled {
    stroke: variables.$PrimaryBackgroundColor;
  }
}
</style>
